// /* You can add global styles to this file, and also import other style files */
// @use './custom.scss';
/* Importing Bootstrap SCSS file. */
@use 'sass:map';
@use 'bootstrap/scss/bootstrap' as * with (
  $accordion-color: #dee2e6,
  $accordion-bg: #495057,
  $accordion-border-radius: 5px,
  $accordion-button-color: #dee2e6,
  $accordion-button-focus-box-shadow: #dee2e6,
  $accordion-icon-color: #dee2e6,
  $accordion-icon-active-color: #dee2e6,
  $accordion-button-active-color: #dee2e6,
  $accordion-button-active-bg: #343a40,
  $accordion-button-padding-y: 0.5rem,

  $accordion-border-width: 0,
  // Create your own map
  $position-values: (
      n3: -3rem,
      n2: -2rem,
      n1: -1px,
      0: 0px,
      1: 0.25rem,
      2: 0.5rem,
      3: 0.625rem,
      4: 1rem,
      5: 1.25rem,
      6: 1.5rem,
      7: 1.75rem,
      8: 2rem,
      10: 2.5rem,
      12: 3rem,
      16: 4rem,
      20: 5rem,
      24: 6rem,
      26: 6.5rem,
      28: 7rem,
      30: 15rem,
      40: 35rem,
      50: 50%,
    ),
  $spacers: (
    0: 0,
    1: 1rem * 0.25,
    2: 1rem * 0.5,
    3: 1rem * 1,
    4: 1rem * 1.5,
    5: 1rem * 2,
    6: 1rem * 2.5,
    7: 1rem * 3,
  ),
  $font-sizes: (
    3xs: 0.5rem,
    2xs: 0.625rem,
    xs: 0.75rem,
    sm: 0.875rem,
    base: 1rem,
    lg: 1.125rem,
    xl: 1.25rem,
    2xl: 1.5rem,
    3xl: 1.875rem,
    4xl: 2.25rem,
    5xl: 2.5rem,
    8xl: 3.5rem,
  ),
  $theme-colors: (
    'primary': #000,
    'hover': #7ebdff,
    'selected': #003253,
    'deactivated': #6c757d,
    'secondary': #f8f9fa,
    'lightgray': #dee2e6,
    'gray': #adb5bd,
    'darkgray': #495057,
    'card': #343a40,
    'dark': #212529,
    'noncritical': #34a85d,
    'warning': #ffe000,
    'dangerous': #ed0007,
    'critical': #440154,
    'fog-hazard': #7ebdff,
    'rain-hazard': #ed0007,
    'slippery-hazard': #ffe000,
    'vehicle-hazard': #fff,
    'provider1-hazard': #007fff,
    'provider2-hazard': #2e8b57,
    'provider3-hazard': #ff9cf5,
    'delete': #ff8800,
  ),
  $grid-breakpoints: (
    xs: 0,
    sm: 700px,
    md: 890px,
    lg: 1100px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1800px,
  ),

  $utilities: (
    'height-fine': (
      property: height,
      class: hf,
      values: (
        xs: 0.3rem,
        xl: 2.25rem,
        4xl: 3.25rem,
      ),
    ),
    'max-width': (
      property: max-width,
      class: mw,
      values: (
        10: 10%,
        20: 20%,
        25: 25%,
        30: 30%,
        35: 35%,
        40: 40%,
        50: 50%,
        90: 90%,
        100: 100%,
      ),
    ),
    'max-width-vw': (
      property: max-width,
      class: max-vw,
      values: (
        50: 50vw,
        65: 65vw,
        100: 100vw,
      ),
    ),
    'max-width-px': (
      property: max-width,
      class: max-wpx,
      values: (
        sm: 260px,
      ),
    ),
    'max-height': (
      property: max-height,
      class: max-vh,
      values: (
        30: 30vh,
        40: 40vh,
        50: 50vh,
        60: 60vh,
        70: 70vh,
        90: 90vh,
        100: 100vh,
      ),
    ),
    'min-height': (
      property: min-height,
      class: min-vh,
      values: (
        30: 30vh,
        40: 40vh,
        50: 50vh,
        60: 60vh,
        70: 70vh,
        90: 90vh,
        100: 100vh,
      ),
    ),
    'min-width': (
      property: min-width,
      class: miw,
      values: (
        15: 15%,
        20: 20%,
        30: 30%,
        40: 40%,
        45: 45%,
        48: 48%,
        49: 49%,
        50: 50%,
        90: 90%,
        100: 100%,
      ),
    ),
    'min-width-vw': (
      property: min-width,
      class: min-vw,
      values: (
        20: 30vh,
        30: 30vh,
        50: 50vh,
        90: 90vh,
        100: 100vh,
      ),
    ),
    'min-width-px': (
      property: min-width,
      class: min-wpx,
      values: (
        40: 40px,
        50: 50px,
        100: 100px,
        150: 150px,
        200: 200px,
        250: 250px,
        300: 300px,
        350: 350px,
        400: 400px,
      ),
    ),
  )
);

@use 'node_modules/@inst-iot/bosch-angular-ui-components/styles/global' as rb with (
  $rb-extended-breakpoints: true,
  $font-path: '~@inst-iot/bosch-angular-ui-components/assets/'
);

@import 'node_modules/@maptiler/geocoding-control/style.css';
@import 'maplibre-gl/dist/maplibre-gl.css';

.textarea {
  resize: none;
}

.maplibregl-ctrl-top-left {
  background-color: #000;
  position: absolute;
  left: 20px;
  top: 60px;
  border-radius: 0.375rem;
  border-color: #495057;
  border-width: 1px;
  border-style: solid;
  z-index: 0;
}
.maplibregl-ctrl-top-right .maplibregl-ctrl-group {
  background-color: transparent;
  position: absolute;
  right: 90px;
  top: 1px;
  border-radius: 0.375rem;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.maplibregl-ctrl button.maplibregl-ctrl-compass .maplibregl-ctrl-icon {
  background-image: url('assets/images/route.svg');
}

.maplibregl-ctrl-top-left .maplibregl-ctrl {
  margin: 0 0 0 0;
}

li.selected.svelte-w9y5n9 .primary.svelte-w9y5n9 {
  color: #7ebdff;
  list-style-type: none;
}

li.selected.svelte-w9y5n9 .primary.svelte-w9y5n9 {
  color: #7ebdff;
}

.secondary.svelte-ltkwvy.svelte-ltkwvy {
  color: #adb5bd;
}

.line2.svelte-w9y5n9.svelte-w9y5n9 {
  color: #adb5bd;
}
li.svelte-w9y5n9.svelte-w9y5n9 {
  --color-text: #adb5bd;
  border-bottom: 1px solid #495057;
  margin-bottom: 0%;
}

li.svelte-w9y5n9.selected::before {
  background-color: transparent;
}

li.svelte-w9y5n9::before {
  background-color: transparent;
}

li.selected.svelte-w9y5n9.svelte-w9y5n9 {
  background-color: #495057;
}

svg.svelte-en2qvf {
  --color-icon-button: #adb5bd;
}
svg.svelte-1bpbt60 {
  --color-icon-button: #adb5bd;
}

svg.svelte-gzo3ar.svelte-gzo3ar {
  display: block;
  fill: #adb5bd;
  stroke: #adb5bd;
}

div.error.svelte-bz0zu3 button.svelte-bz0zu3:hover svg,
div.error.svelte-bz0zu3 button.svelte-bz0zu3:active svg {
  fill: #adb5bd;
}

.marker-reverse svg.svelte-gzo3ar path.svelte-gzo3ar {
  fill: #adb5bd;
  stroke: #adb5bd;
}

form.svelte-bz0zu3.svelte-bz0zu3 {
  font-family:
    Open Sans,
    Ubuntu,
    Helvetica Neue,
    Arial,
    Helvetica,
    sans-serif;
  border-radius: 0.375rem;
  background-color: #212529;
  --color-text: #adb5bd;
  --color-icon-button: #adb5bd;
  box-shadow: NONE;
}

input.svelte-bz0zu3.svelte-bz0zu3:focus {
  color: #adb5bd;
}

input.svelte-bz0zu3.svelte-bz0zu3 {
  color: #adb5bd;
}

button.svelte-bz0zu3:active svg {
  fill: #7ebdff;
}

.input-group.svelte-bz0zu3.svelte-bz0zu3:focus-within {
  outline: #7ebdff solid 1px;
}

ul.svelte-bz0zu3.svelte-bz0zu3,
div.error.svelte-bz0zu3.svelte-bz0zu3,
div.no-results.svelte-bz0zu3.svelte-bz0zu3 {
  list-style-type: none;
  background-color: #212529;
  border-radius: 0.375rem;
  border-color: #495057;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
}

ul.svelte-bz0zu3.svelte-bz0zu3 {
  list-style-type: none;
  font-size: 14px;
  line-height: 16px;
  box-shadow: none;
}

.tooltip-inner {
  background-color: #212529;
  font-size: 90%;
  width: 120px;
}

.custom-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #212529;
}
.custom-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #212529;
}
.custom-tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #212529;
}

// STYLE MANIPULATIONS OF 3rd PARTY COMPONENTS

.layout-main {
  height: calc(100dvh - 14px);
  position: relative;
}

.maplibregl-ctrl.maplibregl-ctrl-attrib {
  background-color: hsla(0, 0%, 50%, 0.5);
}

.maplibregl-ctrl-bottom-left {
  font-size: 0.5rem;
}

@media (hover: hover) {
  /* when hover is supported */

  .hover-effect:hover {
    color: rb.$color-bosch-blue-70 !important;
    fill: rb.$color-bosch-blue-70 !important;
  }

  .hover-app:hover {
    color: rb.$color-bosch-blue-70;
    fill: rb.$color-bosch-blue-70;
    border: 1px;
    border-color: rb.$color-bosch-blue-70;
    cursor: pointer;
  }

  .ho-menu-item:hover {
    cursor: pointer;
    background-color: rb.$color-bosch-blue-70;
    color: white !important;
    border-radius: 5px;
  }

  .ho-layer-item:hover {
    cursor: pointer;
    border: 1px;
    border-color: rb.$color-bosch-blue-70 !important;
    color: rb.$color-bosch-blue-70 !important;
  }

  .ho-layer-text:hover {
    cursor: pointer;
    color: rb.$color-bosch-blue-70 !important;
  }
}

.sel-menu-item {
  cursor: pointer;
  color: white !important;
}

.z-1 {
  z-index: 1;
}

.z-n1 {
  z-index: -1;
}

.rotate-180 {
  rotate: 180deg;
}

.position-marker {
  height: 50px;
  width: 50px;
  background-image: url('assets/images/route.svg');
  background-color: rb.$color-bosch-blue-70;
  border-radius: 40px;
}

.position-circle-marker {
  background: #34c0eb;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.maplibregl-marker svg {
  display: none;
}

.va-marker {
  background-image: url('assets/images/accident-test.png');
  background-size: cover;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.tl-marker {
  background-image: url('assets/images/tl-test.png');
  background-size: cover;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.cs-marker {
  background-image: url('assets/images/cs-test.png');
  background-size: cover;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.bdv-marker {
  background-image: url('assets/images/bdv-test.png');
  background-size: cover;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.lv-marker {
  background-image: url('assets/images/lv-test.png');
  background-size: cover;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.hr-marker {
  background-image: url('assets/images/heavy-rain-test.png');
  background-size: cover;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.hs-marker {
  background-image: url('assets/images/heavy-snow-test.png');
  background-size: cover;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.sw-marker {
  background-image: url('assets/images/strong-wind-test.png');
  background-size: cover;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.legend {
  display: none;
}

.details {
  max-width: 90%;
}

@include media-breakpoint-down(sm) {
  .history {
    bottom: 5rem;
    width: 90%;
  }
  .details {
    max-width: 70%;
    min-width: 70%;
  }
}

@include media-breakpoint-up(sm) {
  .legend {
    display: block;
  }
  .history {
    bottom: 1.75rem;
  }
  .details {
    max-width: 65%;
    min-width: 65%;
  }
}
@include media-breakpoint-up(md) {
  .details {
    min-width: 470px;
    max-width: 40%;
  }
}

@include media-breakpoint-up(xxl) {
  .details {
    min-width: 450px;
    max-width: 25%;
  }
}

@include media-breakpoint-up(xxxl) {
  .details {
    min-width: 450px;
    max-width: 20%;
  }
}

.categories > *:not(:last-child) {
  border-bottom: 1px solid #495057;
}

.blur {
  backdrop-filter: blur(2px);
}

.blur2 {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

// ANIMATIONS
.shake {
  animation-name: shake;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-3px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(3px);
  }
}

.bg-vaw {
  border-width: 2px;
  border-style: solid;
  border-color: #ae4203;
}

.bg-bdvw {
  border-color: #ad9702;
  border-width: 2px;
  border-style: solid;
}

.bg-csw {
  border-width: 2px;
  border-style: solid;
  border-color: #088735;
}

.bg-lvw-area {
  border-width: 2px;
  border-style: solid;
  border-color: #0052a8;
}
.bg-tlw {
  border-width: 2px;
  border-style: solid;
  border-color: #ad0206;
}
.bg-sww-area {
  border-width: 2px;
  border-style: solid;
  border-color: #99c408;
}
.bg-hrw-area {
  border-width: 2px;
  border-style: solid;
  border-color: #d1026d;
}
.bg-hsw-area {
  border-width: 2px;
  border-style: solid;
  border-color: #02d1a1;
}
